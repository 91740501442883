/* App.css */
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.bg-animate {

  background-image: url(../img/blue.jpg);
  background-size: cover;
  height: 100vh;
}